<template>
    <div>

        <div :class="view_class_view.div_container">

            <div :class="view_class_view.div_container_1.class" v-if="view_class_view.div_container_1.show">
                <label for="input_name_beneficiary_name"> {{ names_of_inputs.name }} </label>
                <input type="text" name="input_name_beneficiary_name" id="input_name_beneficiary_name" v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.name.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.name.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.name.$invalid && !_beneficiary_validation.name.$error}"
                    @blur="_beneficiary_validation.name.$validate,formatCamelize(model.name,'name')" v-model="model.name">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.name.$errors" :key="index">
                        <div :id="'error_input_name_beneficiary_name_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
            </div>

            <div :class="view_class_view.div_container_2.class" v-if="view_class_view.div_container_2.show">
                <label for="input_middle_name_beneficiary_name"> {{ names_of_inputs.middle_name }} </label>
                <input type="text" name="input_middle_name_beneficiary_name" id="input_middle_name_beneficiary_name" v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.middle_name.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.middle_name.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.middle_name.$invalid && !_beneficiary_validation.middle_name.$error}"
                    @blur="_beneficiary_validation.middle_name.$validate,formatCamelize(model.middle_name,'middle_name')" v-model="model.middle_name">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.middle_name.$errors" :key="index">
                        <div :id="'error_input_middle_name_beneficiary_name_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
            </div>

            <div :class="view_class_view.div_container_3.class" v-if="view_class_view.div_container_3.show">
                <label for="input_paternal_name_beneficiary_name"> {{ names_of_inputs.paternal_name }} </label>
                <input type="text" name="input_paternal_name_beneficiary_name" id="input_middle_name_beneficiary_name" v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.paternal_name.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.paternal_name.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.paternal_name.$invalid && !_beneficiary_validation.paternal_name.$error}"
                    @blur="_beneficiary_validation.paternal_name.$validate,formatCamelize(model.paternal_name,'paternal_name')" v-model="model.paternal_name">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.paternal_name.$errors" :key="index">
                        <div :id="'error_input_paternal_name_beneficiary_name_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
            </div>

            <div :class="view_class_view.div_container_4.class" v-if="view_class_view.div_container_4.show">
                <label for="input_maternal_name_beneficiary_name"> {{ names_of_inputs.maternal_name }} </label>
                <input type="text" name="input_maternal_name_beneficiary_name" id="input_middle_name_beneficiary_name" v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.maternal_name.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.maternal_name.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.maternal_name.$invalid && !_beneficiary_validation.maternal_name.$error}"
                    @blur="_beneficiary_validation.maternal_name.$validate,formatCamelize(model.maternal_name,'maternal_name')" v-model="model.maternal_name">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.maternal_name.$errors" :key="index">
                        <div :id="'error_input_maternal_name_beneficiary_name_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
            </div>

            <div :class="view_class_view.div_container_5.class" v-if="view_class_view.div_container_5.show">
                <label for="select_relation_ship"> {{ names_of_inputs.relation_ship }} </label>
                <select name="select_relation_ship" id="select_relation_ship" 
                    v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.relation_ship.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.relation_ship.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.relation_ship.$invalid && !_beneficiary_validation.relation_ship.$error}"
                    @blur="_beneficiary_validation.relation_ship.$validate" v-model="model.relation_ship">
                        <option value = "" selected disabled >Seleccionar una opción</option>
                        <option v-for="(relation_ship, index) in relation_ship_List" :key="index" :id="`select_relation_ship${relation_ship.toLowerCase()}_${index}`" :value="relation_ship" >{{ relation_ship.toUpperCase() }}</option>
                </select> 

                <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.relation_ship.$errors" :key="index">
                    <div :id="'error_relation_ship_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                </div>         
               
            </div>

            <div :class="view_class_view.div_container_6.class" v-if="view_class_view.div_container_6.show">
                <label for="input_phone_name">{{ names_of_inputs.phone }}</label>
                  <input type="text" name="input_phone_name" id="input_phone_name" inputmode="numeric" 
                  v-maska="'#### ##-##-##'"
                  v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.phone.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.phone.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.phone.$invalid && !_beneficiary_validation.phone.$error}"
                    @blur="_beneficiary_validation.phone.$validate" v-model="model.phone">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.phone.$errors" :key="index">
                        <div :id="'error_phone_name_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
               
            </div>

            <div :class="view_class_view.div_container_7.class" v-if="view_class_view.div_container_7.show">
                <label for="input_porcentage_beneficiary"> {{ names_of_inputs.date_birthday }} </label>
                <VueDatePickerNew
                  teleport-center
                  :max-date="today"
                  :clearable="false"
                  v-model="model.date_birthday"
                  :enable-time-picker="false"
                  @update:model-value="selectDate($event)"
                  :flow="['year', 'month', 'calendar']"
                  format="dd/MM/yyyy"
                  model-type="dd/MM/yyyy"
                  locale="es"
                  cancelText="Cancelar"
                  selectText="Seleccionar"
                  placeholder="DD/MM/YYYY"
                />
                <!-- <date-picker
                    simple
                    locale="es"
                    input-format="DD/MM/YYYY"
                    id="date_birthday"
                    v-model = model.date_birthday
                    @change="selectDate($event)"
                    :localeConfig="{
                    es: {
                        displayFormat: 'DD/MM/YYYY',
                        lang: {
                            year: 'Año',
                            month: 'Mes',
                            day: 'Día',
                            label: '',
                            submit: 'Seleccionar',
                            cancel: 'Cancelar',
                            now: 'Hoy',
                            nextMonth: 'Siguiente',
                            prevMonth: 'Anterior',
                        }
                    }
                }"/> -->

                <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.date_birthday.$errors" :key="index">
                    <div :id="'error_input_porcentage_beneficiary_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                </div>
            </div>

            <div v-if="view_class_view.div_container_8.show" :class="view_class_view.div_container_8.class">
                <ChooseButton
                    ref="chooseButtonRef"
                    :buttons="buttonsGender"
                    v-on:choose-button="getValueChooseButton($event)"
                    :label="names_of_inputs.gender"
                />

                <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.gender.$errors" :key="index">
                    <div :id="'error_button_gender_beneficiary_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                </div> 
            </div>


            <div :class="view_class_view.div_container_9.class" v-if="view_class_view.div_container_9.show">
                <label for="input_porcentage_beneficiary">{{ names_of_inputs.porcentage }}</label>
                <input type="number" inputmode="numeric" name="input_porcentage_beneficiary" id="input_porcentage_beneficiary" v-bind:class="{[ view_class_view.classInputs.default + view_class_view.classInputs.no_valid ]: _beneficiary_validation.porcentage.$error,
                    [view_class_view.classInputs.default + view_class_view.classInputs.valid]:!_beneficiary_validation.porcentage.$invalid,
                    [view_class_view.classInputs.default ]: _beneficiary_validation.porcentage.$invalid && !_beneficiary_validation.name.$error}"
                    @blur="_beneficiary_validation.porcentage.$validate" v-model="model.porcentage"
                    :max="100" :min="1">

                    <div :class="view_class_view.div_container_error.container" v-for="(error,index) of _beneficiary_validation.porcentage.$errors" :key="index">
                        <div :id="'error_input_porcentage_beneficiary_'+index" :class="view_class_view.div_container_error.text_error">{{ error.$message }}</div>
                    </div>
            </div>

        </div>

    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";
import moment from "moment";
import { ref, watch } from "vue";

export default defineComponent({
    name:"BeneficiaryForm",
    props:{
        view_class_view:{
            type: Object,
            default: {}
        },
        validations:{
            type: Object,
            default: {}
        },
        names_of_inputs:{
            type: Object,
            default: {},
            required:true
        }
    },
    setup(props){

        

        const chooseButtonRef = ref();

        const relation_ship_List = ref(["Conyuge","Hijo","Padre","Madre","Hermano","Abuelo","Tio","Pareja","Sobrino","Nieto","Amigo","Yerno","Nuera","Tutor","Primo","Ninguno"]);

        const model = ref({ name:"", middle_name:"", paternal_name:"", maternal_name:"", relation_ship:"", porcentage:"", date_birthday:"", gender:"", phone:"" })
        const _beneficiary_validation = useVuelidate(props.validations, model);

        function selectDate(date){
            _beneficiary_validation.value.date_birthday.$reset();
            _beneficiary_validation.value.date_birthday.$touch();
            // model.value.date_birthday = moment(date._d).format("DD/MM/YYYY");
        }

        function validate(){

            _beneficiary_validation.value.$validate();

            if(_beneficiary_validation.value.$error){
                return false;
            }

            return model.value;

        }

        const buttonsGender = [
            {
                id: "button_gender_male_personal_info",
                value: "masculino",
                text: "Hombre",
            },
            {
                id: "button_gender_female_personal_info",
                value: "femenino",
                text: "Mujer",
            },
        ];

        function getValueChooseButton(generSelected){
            model.value.gender = generSelected;
        }

        function formatCamelize(str,node_key) {
        let response = ""
            if (str != "" && typeof str != 'undefined') {
                str = str.toString().toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
                    return match.charAt(match.length - 1).toUpperCase();
                });
                response = str.charAt(0).toUpperCase() + str.substring(1);
                model.value[node_key] = response.replace(/([A-Z])/g, ' $1').trim();
            }
        }

        return {
            _beneficiary_validation,
            model,
            relation_ship_List,
            selectDate,
            validate,
            buttonsGender,
            getValueChooseButton,
            chooseButtonRef,
            formatCamelize
        }
    }
});
</script>