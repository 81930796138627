<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
           
            <div class="col-span-6">

                <div class="px-10 py-10 w-full">
                    
                    <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Beneficiarios</h4>
                    <div style="display:flex">
                        <div class="bg-yellow-400 h-1 w-24"></div>
                    </div>

                    <div class="mt-5">
                        <Beneficiaries />
                    </div>

                </div>

            </div>
            
        </div>
      </body>
    </html>
</template>

<script>

import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from '@/components/Footer.vue'
import Beneficiaries from "@/components/Cuenta/BeneficiariosComponent.vue";

export default  {
    name:"BeneficiariesView",
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateral,
        MenuLateralMobile,
        Footer,
        Beneficiaries
    },
    setup(){

    }

}

</script>

<style scoped>
/* .liner-yellow{
    width: 5%;
    background: #f8c603;
    height: 5px; 
} */
</style>