const host = process.env.VUE_APP_CCAPI;
import api from '@/api/api';
import Response from './Response';
import { getTokenDecoden, getUserInfo } from "@/helpers/tokenauth.js";
export default class Beneficiaries {
    
    id = "";
    birthday = "";
    gender = "";
    name = "";
    middle_name = "";
    paternal_name = "";
    maternal_name = "";
    percentage = "";
    relationship = "";

    constructor( { id ,date_birthday, gender, maternal_name, middle_name, name, paternal_name, porcentage, relation_ship })
    {
        this.id = id == "" || id == null ? "0" : id ;
        this.birthday = date_birthday;
        this.gender = gender;
        this.name = name;
        this.middle_name = middle_name;
        this.paternal_name = paternal_name;
        this.maternal_name = maternal_name;
        this.percentage = porcentage;
        this.relationship = relation_ship;
    }

    static async search_by_rfc(client_id)
    {

        try 
        {
            const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_beneficiaries`;
            const API_URL = `${host}${resource}`;

            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            };

            const params = {
                token_auth: sessionStorage.getItem("login"),
                client_id
            };

            const response =  await api.httpGet(API_URL, {config}, params);

            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;

        } catch (err) {
            const _response = new Response( { data: err.response.data } );
            _response.assing_messaje();
            return _response;
        }
        
    }

    static async update({beneficiaries,client_id})
    {
        try 
        {
            const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/beneficiaries/update`;
            const API_URL = `${host}${resource}`;

            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            };

            const params = {
                token_auth: sessionStorage.getItem("login"),
                client_id,
                beneficiaries
            };

            const response =  await api.httpPost(API_URL, config, params);
            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;

        } catch (err) {
            const _response = new Response( { data: response.data } );
            
            _response.assing_messaje();
            _response.status = false;
            
            return _response;
        }
    }

    static async generate_pdf_update({beneficiaries})
    {
        try 
        {
            const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/beneficiaries/generate_pdf_update_beneficiaries`;
            const API_URL = `${host}${resource}`;

            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            };

            const params = {
                token_auth: sessionStorage.getItem("login"),
                full_name:(getUserInfo().full_name).toUpperCase(),
                email:getTokenDecoden().obj.email,
                beneficiaries
            };

            const response =  await api.httpPost(API_URL, config, params);
            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;

        } catch (err) {
            console.log(err)
            const _response = new Response( { data: response.data } );
            
            _response.assing_messaje();
            _response.status = false;
            
            return _response;
        }
    }
}