<template>
    
    <div>
        <Loading :isLoading="isLoading" />
        <!-- px-5 py-5 -->
        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5" v-for="(beneficiarie, index) of array_of_beneficiaries" :key="index">
            <div class="flex">

                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                    <div class="h-16 w-16 rounded-full bg-green-600 m-auto flex justify-center items-center" @click=" open_modal_add_beneficiarie({ editable: true, beneficiarie:beneficiarie, position:index })">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div>
                        <div class="text-gray-400">
                            Nombre completo beneficiario:
                        </div>
                        <div class="text-gray-700">
                            {{ (`${beneficiarie["name"]} ${beneficiarie["middle_name"]} ${beneficiarie["paternal_name"]} ${beneficiarie["maternal_name"]}`).toLocaleUpperCase() }}
                        </div>
                    </div>

                    <div class="mt-5">
                        <div class="text-gray-400">
                            Porcentaje:
                        </div>
                        <div>
                            {{beneficiarie.percentage}} %
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <div class="mt-5">
            <div>
                Porcentaje Total: <strong>{{total_percentage}}%</strong>
            </div>

            <div class="text-gray-400 italic" v-show="total_percentage != 100">
                Se debe completar el <strong>100%</strong> de los beneficiarios
            </div>

            <div class="w-full mt-5" v-show="total_percentage < 100">
                <button class="bg-yellow-400 border-2 px-5 py-4 text-white rounded-md w-full font-bold text-md focus:outline-none hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all"
                @click="open_modal_add_beneficiarie">Añadir Beneficiario</button>
            </div>

            <div class="w-full mt-5" v-show="total_percentage == 100 && changes">
                <label class="text-gray-400 italic" > Presione aquí para guardar los cambios </label>
                <button class="bg-yellow-400 border-2 px-5 py-4 text-white rounded-md w-full font-bold text-md focus:outline-none hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all"
                @click="update_beneficiarie">Generar solicitud de cambio</button>
            </div>

        </div>


        <Modal v-show="showModalAddBeneficiarie" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-cherryColor text-3xl">Agregar Beneficiario</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end" @click="close_modal()" >X</label>
                
                </div>

            </template>
            
            <template v-slot:body>
                <div>
                    <BeneficiaryForm ref="beneficiaryRef" :view_class_view="viewClassView" :validations="rules_validations" :names_of_inputs="beneficiarie_inputs_name" />
                </div>
            </template>
            
            <template v-slot:footer>
                <button class="bg-yellow-400 border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all"
                    v-show="!mode_editable.editable"
                    @click="save_beneficiarie">Guardar beneficiario
                </button>
                <button class="bg-red-700 border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-red-700 border-red-700 transition-all"
                    v-show="mode_editable.editable"
                    @click="remove">Eliminar
                </button>
                <button class="bg-yellow-400 border-2 px-5 py-4 mt-5
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all"
                    v-show="mode_editable.editable"
                    @click="save_editable">Guardar
                </button>
            </template>
        </Modal>
        




    </div>

</template>

<script> 
    import Modal from '@/components/Modal/Modal.vue'
    import { ref, onMounted } from "vue";
    import { helpers, maxLength, minLength, requiredIf } from "@vuelidate/validators";
    import  BeneficiaryForm from "@/components/Forms/BeneficiariesForm.vue";
    import { date_select_is_future } from "@/helpers/dates"
    import Swal from "sweetalert2";
    import BeneficiarieClass  from "@/classes/Beneficiaries";
    import { getAgeByBirthDate } from "@/helpers/dates.js"
    import { getTokenDecoden } from "@/helpers/tokenauth.js"
    import { useRouter } from "vue-router";
    import { formatCamelize } from "@/helpers/filters";
    import moment from 'moment';
    import Loading from '@/components/Loading/VueLoading.vue';


    export default {
        name:"BeneficiariosComponent",
        components:{
            Modal,
            BeneficiaryForm,
            Loading
        },
        setup(){
            
            const benefEditInitialPercentage = ref(0)
            const beneficiaries_arry_copy = ref([]);


            function close_modal(){
                total_percentage.value += benefEditInitialPercentage.value
                benefEditInitialPercentage.value = 0
                showModalAddBeneficiarie.value = false
            }

            const available = ref(100);

            const beneficiarie_inputs_name = ref(
                {
                    name:"Nombre:*",
                    middle_name:"Segundo nombre:",
                    paternal_name:"Apellido paterno:*",
                    maternal_name:"Apellido materno:*",
                    relation_ship:"Parentesco:*",
                    phone:"Teléfono:",
                    date_birthday:"Fecha de nacimiento:*",
                    gender:"Genero:*",
                    porcentage:"Porcentaje:*"
                }
            );
            
            const isLoading = ref(false);
            const showModalAddBeneficiarie = ref(false);
            const beneficiaryRef = ref(null);
            const array_of_beneficiaries = ref([]);
            const total_percentage = ref(0);
            const router = useRouter();
            const mode_editable = ref({
                position:0,
                editable:false,
                change:false
            });
            const changes = ref(false)

            const user_data = ref({});


            onMounted(async () => {
                user_data.value = getTokenDecoden()
                await get_beneficiaries(user_data.value.obj.person.client_id);
                
                // client_data.value = getTokenDecoden();
                // rfc_without_homoclave(client_data.value.obj.person);
                // const response = await get_client_data(client_data.value.obj.person);
                // if(!response) return;
                // clientClass.value = new Client(response);
            })
            
            function open_modal_add_beneficiarie( { editable = false, beneficiarie = null, position = 0 } ){
                mode_editable.value.editable = false;
                reset_form();
                if(editable){
                    beneficiaries_arry_copy.value = JSON.parse(JSON.stringify(array_of_beneficiaries.value))
                    if(!(Object.keys(beneficiarie).includes("date_birthday")))
                    {
                        beneficiarie.date_birthday = beneficiarie.birthday.split("-").reverse().join("/")
                    }
                    mode_editable.value.editable = true;
                    mode_editable.value.position = position;
                    edit(beneficiarie);
                }

                showModalAddBeneficiarie.value = true

            }

            function get_data_of_component()
            {
                const response = beneficiaryRef.value.validate();

                if( !response){
                    
                    showModalAddBeneficiarie.value = false
                    
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Favor de completar los campos solicitados.",
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            showModalAddBeneficiarie.value = true;
                        }
                    });

                    return false;
                }

                return response;
            }

            function save_beneficiarie(){

                const response = get_data_of_component();
                
                if(!response)
                return 

                const is_allocated = total_percentage_allocated();

                if(are_maximum_beneficiaries())
                {
                    showModalAddBeneficiarie.value = false
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Solo puedes tener 5 beneficiarios.",
                        allowOutsideClick:false
                    })
                    return;
                }

                if(is_allocated)
                {
                    showModalAddBeneficiarie.value = false;
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Ya se encuentra asignado el 100% para los beneficiarios.",
                        allowOutsideClick:false
                    })
                    return;
                }


                if((total_percentage.value + response.porcentage) > 100)
                {
                    showModalAddBeneficiarie.value = false;
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"El porcentaje asignado hace que exceda del 100%.",
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            showModalAddBeneficiarie.value = true;
                        }
                    });
                    return;
                }

                showModalAddBeneficiarie.value = false;

                array_of_beneficiaries.value.push(new BeneficiarieClass(response) );

                reset_form();
                calculate_percentage();

            }

            function total_percentage_allocated()
            {
                if(total_percentage.value == 100)
                    return true;

                return false;

            }

            function reset_form()
            {
                beneficiaryRef.value.model.paternal_name = "";
                beneficiaryRef.value.model.maternal_name = "";
                beneficiaryRef.value.model.name = "";
                beneficiaryRef.value.model.middle_name = "";
                beneficiaryRef.value.model.relation_ship = "";
                beneficiaryRef.value.model.porcentage = "";
                beneficiaryRef.value.model.date_birthday = "";
                beneficiaryRef.value.model.gender = "";
                beneficiaryRef.value.model.phone = "";
                beneficiaryRef.value.model.id = "";

                beneficiaryRef.value._beneficiary_validation.$reset();

            }

            function are_maximum_beneficiaries()
            {
                return array_of_beneficiaries.value.length == 5
            }

            async function get_beneficiaries(client_id)
            {
                const response = await BeneficiarieClass.search_by_rfc(client_id);
                
                if(!response.status)
                {
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:response.message,
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                    return;
                }

                total_percentage.value = 100;
                array_of_beneficiaries.value = response.data;
                available.value = 0;
            }

            function edit(beneficiarie)
            {
                benefEditInitialPercentage.value = beneficiarie.percentage
                total_percentage.value -= beneficiarie.percentage;
                beneficiaryRef.value.model.paternal_name = beneficiarie.paternal_name;
                beneficiaryRef.value.model.maternal_name = beneficiarie.maternal_name;
                beneficiaryRef.value.model.name = beneficiarie.name;
                beneficiaryRef.value.model.middle_name = beneficiarie.middle_name;
                beneficiaryRef.value.model.relation_ship = formatCamelize(beneficiarie.relationship);
                beneficiaryRef.value.model.porcentage = beneficiarie.percentage;
                beneficiaryRef.value.model.date_birthday = beneficiarie.date_birthday;  
            }

            function save_editable()
            {
                changes.value = true;;
                const response = get_data_of_component();
                
                if(!response)
                return;

                const total = total_percentage.value - array_of_beneficiaries.value[mode_editable.value.position].percentage;

                if( (total + response.porcentage) > 100  )
                {
                    showModalAddBeneficiarie.value = false;
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"El porcentaje entre todos los beneficiarios debe ser 100%.",
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            showModalAddBeneficiarie.value = true;
                        }
                    });
                    return
                }
                
                response.id = JSON.stringify(array_of_beneficiaries.value[mode_editable.value.position].id);
                array_of_beneficiaries.value[mode_editable.value.position] = new BeneficiarieClass(response);

                showModalAddBeneficiarie.value = false;
                mode_editable.value.change = true;
                reset_form();
                calculate_percentage();
            }

            function calculate_percentage()
            {
                available.value = 100;
                total_percentage.value = 0;
                array_of_beneficiaries.value.map( (beneficiarie) =>  total_percentage.value += beneficiarie.percentage );
                available.value-=total_percentage.value;
            }

            async function update_beneficiarie()
            {
                if(!(total_percentage.value == 100)){
                    
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Favor de assigar el 100 a los beneficiarios.",
                        allowOutsideClick:false
                    });
                    return;
                    
                }

                isLoading.value = true;

                const response = await BeneficiarieClass.update({beneficiaries:array_of_beneficiaries.value,client_id:user_data.value.obj.person.client_id})
                
                isLoading.value = false;

                if(!response.status)
                {
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:response.message,
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                    return;
                }

                Swal.fire({
                    icon:"success",
                    title:"Se ha enviado por correo la solicitud de cambio de beneficiario",
                    text:"Beneficiarios actualizados.",
                    confirmButtonColor: '#FEB72B',
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });

                
                const generate_response = await BeneficiarieClass.generate_pdf_update({beneficiaries:array_of_beneficiaries.value});

                if(!generate_response.status)
                {
                    Swal.fire({
                        icon:"warning",
                        title:"Cuidado!",
                        text:response.message,
                        allowOutsideClick:false
                    }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                    return;
                }

            }

            function remove(){
                changes.value = true;
                showModalAddBeneficiarie.value = false;
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:"¿Está seguro que desea eliminar este beneficiario?",
                    confirmButtonText:"Sí",
                    showCancelButton:true,
                    cancelButtonText:"No",
                    reverseButtons:true
                }).then( (res)=>{
                    if(res.value){
                        // available_balance.value = available_balance.value - Number(beneficiaries.value[position].percentage);
                        available.value+= Number(array_of_beneficiaries.value[mode_editable.value.position].percentage);
                        array_of_beneficiaries.value.splice(mode_editable.value.position,1);
                        Swal.fire({
                            icon:"success",
                            title:"",
                            text:"Beneficiario eliminado."
                        });
                        calculate_percentage();
                    }
                    else{
                        recovery_array_beneficiaries();
                    }
                })
            }

            const viewClassView = {
                classInputs: {
                    valid:"border-blue-600",
                    no_valid:"border-red-600",
                    default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
                },
                div_container_error: {
                    container: "my-2",
                    text_error: "text-red-600",
                },
                div_container:"",
                div_container_1:{
                    class:"",
                    show:true
                },
                div_container_2:{
                    class:"",
                    show:true
                },
                div_container_3:{
                    class:"",
                    show:true
                },
                div_container_4:{
                    class:"",
                    show:true
                },
                div_container_5:{
                    class:"",
                    show:true
                },
                div_container_6:{
                    class:"",
                    show:false
                },
                div_container_7:{
                    class:"",
                    show:true
                },
                div_container_8:{
                    class:"mt-2",
                    show:false
                },
                div_container_9:{
                    class:"mt-2",
                    show:true
                },
            };
            
            const total_porcentage = ref(0);

            const rules_validations = {
                name:{
                    required:helpers.withMessage("El campo nombre es requerido.", requiredIf(true)),
                    isAlphaNumeric:helpers.withMessage(`El campo nombre no admite caracteres especiales.`,(data,rules_validations_index)=>rules_validations_index.name != ""? !new RegExp(/[^a-zA-ZÀ-ÿ@\s-]+/).test(rules_validations_index.name): true),
                    we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,rules_validations_index)=>rules_validations_index.name != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(rules_validations_index.name): true),
                    minLength: helpers.withMessage(`El campo nombre tiene que contener mínimo 2 caracteres.`, minLength(2)),
                    maxLength: helpers.withMessage(`El campo nombre tiene que contener maximo 50 caracteres.`, maxLength(50)),
                },
                middle_name:{
                    required:helpers.withMessage("El campo segundo nombre es requerido", requiredIf(false)),
                    isAlphaNumeric:helpers.withMessage(`El campo nombre no admite caracteres especiales.`,(data,rules_validations_index)=>rules_validations_index.middle_name != ""? !new RegExp(/[^a-zA-ZÀ-ÿ@\s-]+/).test(rules_validations_index.middle_name): true),
                    we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,rules_validations_index)=>rules_validations_index.middle_name != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(rules_validations_index.middle_name): true),
                    minLength: helpers.withMessage(`El campo nombre tiene que contener mínimo 2 caracteres.`, minLength(2)),
                    maxLength: helpers.withMessage(`El campo nombre tiene que contener maximo 50 caracteres.`, maxLength(50)),
                },
                paternal_name:{
                    required:helpers.withMessage("El campo apellido paterno es requerido",  function (data,rules_validations_index) {
                        if(rules_validations_index.maternal_name == ""){
                            if(rules_validations_index.maternal_name == "" && rules_validations_index.paternal_name != ""){
                                beneficiarie_inputs_name.value.maternal_name = "Apellido materno:"
                            }
                            beneficiarie_inputs_name.value.paternal_name = "Apellido paterno:*"
                            return rules_validations_index.paternal_name != "";
                        }else{
                            return true;
                        }
                    }),
                    isAlphaNumeric:helpers.withMessage(`El campo nombre no admite caracteres especiales.`,(data,rules_validations_index)=>rules_validations_index.paternal_name != ""? !new RegExp(/[^a-zA-ZÀ-ÿ@\s-]+/).test(rules_validations_index.paternal_name): true),
                    we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,rules_validations_index)=>rules_validations_index.paternal_name != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(rules_validations_index.paternal_name): true),
                    minLength: helpers.withMessage(`El campo nombre tiene que contener mínimo 2 caracteres.`, minLength(2)),
                    maxLength: helpers.withMessage(`El campo nombre tiene que contener maximo 50 caracteres.`, maxLength(50)),
                },
                maternal_name:{
                    required:helpers.withMessage("El campo apellido materno es requerido", function (data,rules_validations_index) {
                        if(rules_validations_index.paternal_name == ""){
                            if(rules_validations_index.paternal_name == "" && rules_validations_index.maternal_name != ""){
                                beneficiarie_inputs_name.value.paternal_name =  "Apellido paterno:"
                            }
                            beneficiarie_inputs_name.value.maternal_name = "Apellido materno:*"
                            return rules_validations_index.maternal_name != "";
                        }else{
                            return true;
                        }
                    }),
                    isAlphaNumeric:helpers.withMessage(`El campo nombre no admite caracteres especiales.`,(data,rules_validations_index)=>rules_validations_index.maternal_name != ""? !new RegExp(/[^a-zA-ZÀ-ÿ@\s-]+/).test(rules_validations_index.maternal_name): true),
                    we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,rules_validations_index)=>rules_validations_index.maternal_name != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(rules_validations_index.maternal_name): true),
                    minLength: helpers.withMessage(`El campo nombre tiene que contener mínimo 2 caracteres.`, minLength(2)),
                    maxLength: helpers.withMessage(`El campo nombre tiene que contener maximo 50 caracteres.`, maxLength(50)),
                },
                relation_ship:{
                    required:helpers.withMessage("El campo parentesco es requerido", requiredIf(true)),
                    has_conyuge:helpers.withMessage("Ya existe beneficiario conyuge", (value, index)=>{
                        if(index.relation_ship.toLowerCase() == "conyuge"){
                            console.log("array_of_beneficiaries",array_of_beneficiaries)
                            var exists_conyuge = array_of_beneficiaries.value.filter((be)=>{ if(be.relationship.toLowerCase() == "conyuge"){ return true } }).length != 0
                            return !exists_conyuge
                        }
                        return true
                    }),
                },
                porcentage:{
                    required:helpers.withMessage("El campo porcentaje es requerido", requiredIf(true)),
                    more_that:helpers.withMessage("El campo porcentaje tiene que ser mayor que 0 ", function(data,rules_validations_index) {
                        return Number(rules_validations_index.porcentage) > 0;
                    }),
                    valueTwoNumber:helpers.withMessage("No se admiten decimales.",function(data,rules_validations_index) {
                        if(rules_validations_index.porcentage!=""){
                            return new RegExp(/^[0-9]{1,3}$/).test(rules_validations_index.porcentage.toString())
                        }
                        return true;
                    }),
                    more_that1:helpers.withMessage( (data, index) => {

                        if((data.$model + total_percentage.value) > 100){
                            return `El porcentaje debe de ser menor o igual ${100-total_percentage.value}`
                        }

                    }, function(data,rules_validations_index) {
                        return (Number(rules_validations_index.porcentage) + total_percentage.value) <= 100;
                    }),
                    more_that2:helpers.withMessage( (data, index) => {

                        if((data.$model + total_percentage.value) > 100){
                            return 'El monto seleccionado hace exceder el 100%'
                        }

                    }, function(data,rules_validations_index) {
                        return (Number(rules_validations_index.porcentage) + total_percentage.value) <= 100;
                    }),

                    $autoDirty: true
                },
                date_birthday:{
                    required:helpers.withMessage("El campo fecha nacimiento es requerido", requiredIf(true)),
                    date_select_is_future: helpers.withMessage(`La fecha selecionada no es permitida.`, function(data,rules_validations_index) { 
                        if(rules_validations_index.date_birthday != "" && rules_validations_index.date_birthday != "Invalid date"){
                            return !(date_select_is_future(rules_validations_index.date_birthday));
                        }
                        return true
                    }),
                    awfawfw:helpers.withMessage("El campo fecha de nacimiento es invalido.",function(data,rules_validations_index){
                        let client_birthday = get_date_Birth(user_data.value.obj.person.rfc)
                        const beneficiarie_birthday= rules_validations_index.date_birthday.split("/").reverse().join("-");


                        if(rules_validations_index.relation_ship == "Hijo" && ((moment(client_birthday)) > (moment(beneficiarie_birthday))) ){
                            showModalAddBeneficiarie.value=false;
                            
                            Swal.fire({
                                title: "Aviso",
                                text: "La fecha de nacimiento del beneficiario hijo no puede ser mayor a la del titular/socio.",
                                icon: "warning",
                            }).then(response => {
                                if(response){
                                    showModalAddBeneficiarie.value = true;
                                }
                            });    
                            return false
                        }

                        if( ( (moment( beneficiarie_birthday)) > (moment(client_birthday))) && (rules_validations_index.relation_ship == "Padre" || rules_validations_index.relation_ship == "Madre" || rules_validations_index.relation_ship == "Abuelo")){
                            showModalAddBeneficiarie.value=false;
                            
                            Swal.fire({
                                title: "Aviso",
                                text: rules_validations_index.relation_ship == "ABUELO" ? "La fecha de nacimiento del Auelo no debe ser menor a la del titular/socio." : "La fecha de nacimiento del padre/madre no debe ser menor a la del titular/socio.",
                                icon: "warning",
                            }).then(response => {
                                if(response){
                                    showModalAddBeneficiarie.value = true;
                                }
                            });      
                            return false;
                        }
                        
                        if(rules_validations_index.date_birthday != "")
                        {
                            const bb = getAgeByBirthDate(beneficiarie_birthday.split("-").reverse().join("-"))
                            if( (bb < 18) && (rules_validations_index.relation_ship == "Conyuge")){
                                showModalAddBeneficiarie.value=false;
                                Swal.fire({
                                    title: "Aviso",
                                    text: "El Cónyuge debe ser mayor de edad.",
                                    icon: "warning",
                                    allowOutsideClick:false
                                }).then(response => {
                                    if(response){
                                        showModalAddBeneficiarie.value = true;
                                    }
                                });     
                                return false;
                            }

                            const beneficiarie_age = moment(rules_validations_index.date_birthday.split("/").reverse().join("-")).diff(moment(client_birthday),'years'); 

                            if(rules_validations_index.relation_ship == "Hijo" && !(beneficiarie_age >= 12) ){
                                showModalAddBeneficiarie.value=false;
                                Swal.fire({
                                    title: "Aviso",
                                    text: 'La diferencia de edad  entre socio y su beneficiario "Hija" o "Hijo", debe de ser mayor igual a 12 años.',
                                    icon: "warning",
                                }).then(response => {
                                    if(response){
                                        showModalAddBeneficiarie.value = true;
                                    }
                                });    
                                return false
                            }
                        }


                        return true
                    })
                },
                gender:{
                    required:helpers.withMessage("el campo género es requerido.", requiredIf(false)),
                },
                phone:{
                    required:helpers.withMessage("El campo teléfono es requerido.", requiredIf(false)),
                }
            }

            function calculate_available_array_copy() {
                available.value = 100;
                total_porcentage.value = 0;
                console.log("beneficiaries_arry_copy",beneficiaries_arry_copy)
                array_of_beneficiaries.value.map( (beneficiarie) =>  total_porcentage.value += beneficiarie.percentage );
                available.value-=total_porcentage.value;
            }

            function get_date_Birth(val)
            {
                const date = new Date()
                const yearPlusOne = date.setFullYear(date.getFullYear() +1 )
                const yearPlusOneSub = new Date(yearPlusOne).getFullYear().toString().substring(2,4);
                    
                const extractFullDate = val.substring(4, 10);
                const yearRFC = extractFullDate.substring(0, 2);
                const getYearFormat = yearPlusOneSub > yearRFC ? `20${yearRFC}`: `19${yearRFC}`

                        
                const month = extractFullDate.substring(2, 4);
                const day = extractFullDate.substring(4, 6);

                return `${getYearFormat}-${month}-${day}`;
                // this.personalInfo.dateBirth = formatYear
            }

            function editing_beneficiarie(porcentage) {
                return mode_editable.value.editable && porcentage != "" && porcentage != undefined;
                // if()
                // {
                //     // beneficiaries_arry_copy.value[mode_editable.value.position].percentage = porcentage;
                // }

                
            }

            return {
                showModalAddBeneficiarie,
                open_modal_add_beneficiarie,
                viewClassView,
                rules_validations,
                beneficiaryRef,
                save_beneficiarie,
                array_of_beneficiaries,
                total_percentage,
                mode_editable,
                save_editable,
                update_beneficiarie,
                remove,
                isLoading,
                beneficiarie_inputs_name,
                close_modal,
                changes
            }
        }
    }

</script>